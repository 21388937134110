// Globals
//
// Every element has `box-sizing: border-box`.
//
// Body min-width is the smaller breakpoint.
//
// Styleguide Globals
* {
  box-sizing: border-box;
}

html {
  font-family: $default-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  scroll-behavior: smooth;
}

body {
  font-family: $primary-font-family;
  background: $color-white--light;
  color: $color-black--medium;
  min-width: $xs;
  opacity: 1;
}

// Headings
//
// Styleguide Elements.Headings

// Heading 1
//
// Markup:
// <h1>Main heading</h1>
//
// Styleguide Elements.Headings.Heading1
h1 {
  // @include type-setting(xlarge);
  // font-weight: normal;
  margin: 0; //map-get($line-heights, xlarge) auto;
  @include type-setting(large);
  // line-height: 0;
  font-weight: normal;
  // display: inline-block;
}

// Heading 2
//
// Markup:
// <h2>Heading with background color</h2>
//
// Styleguide Elements.Headings.Heading2
h2:not(.h3) {
  @include type-setting(large);
  font-weight: normal;
  display: inline-block;
  background-color: $color-black--medium;
  color: $color-white--light;
  padding: .625rem 1.25rem .625rem 5%;
  margin-top: 2rem;
  &.with-icon {
    a { border-bottom: 0; }
  }
}
@media (min-width: 1200px) {
  h2:not(.h3) {
    min-width: 42.5%;
    text-align: right;
  }
}

// Heading 3
//
// Markup:
// <h3>Section heading</h3>
// <p class="subheader">An element following the h3 with class .subheader will have zero margin</p>
//
// Styleguide Elements.Headings.Heading3
h3, .h3 {
  @include type-setting(large);
  font-weight: normal;
  font-family: $secondary-font-family;
  // margin-bottom: 0;
}

// Paragraph
//
// Markup:
// <p>Paragraph with some text<br>and a break to demonstrate line height.</p>
// <p>Another paragraph here for checking the margins.</p>
//
// Styleguide Elements.Paragraph
p {
  @include type-setting(normal);
  margin: 1rem auto;
  // max-width: $md;
}

// Anchor
//
// Markup:
// <a href="#" target="_blank">A Link</a>
//
// Styleguide Elements.Anchor
a {
  text-decoration: none;
  color: $color-black--medium;
  padding-bottom: .1rem;
  border-bottom: 1px solid $color-gray--medium;
  transition: all $transition-fast;
  opacity: .95;
  &:hover, &:focus {
    border-bottom-color: $color-black--dark;
    opacity: 1;
    color: $color-black--dark;
  }
}

// Unordered list
//
// Markup:
// <ul>
//   <li>A List item</li>
//   <li>Another List item</li>
// </ul>
//
// Styleguide Elements.UnorderedList
ul, ol {
  list-style-type: square;
  margin: 0;
  padding: 0;
}


// Definition list
//
// Markup:
// <dl>
//   <dt>
//     <svg class="c-icon c-icon--large" aria-hidden="true">
//       <use xlink:href="#icon-envelope"></use>
//     </svg>
//     <span>A definition term</span>
//   </dt>
//   <dd>The data definition</dd>
// </dl>
//
// Styleguide Elements.DefinitionList
dl {
  dt {
    font-weight: bold;
    display: flex;
    svg { margin-right: .5rem; }
    span { align-self: center; }
  }
  dd { margin-bottom: 1rem; }
}

// Blockquote
//
// Markup:
// <blockquote>
//   This is a quote
// </blockquote>
//
// Styleguide Elements.Blockquote
blockquote {
  padding: 0 1rem;
  border-left: .2rem solid $color-white--medium;
  margin: 0;
}

// Pre/Code
//
// Markup:
// <pre>
//   <code>This is code</code>
// </pre>
//
// Styleguide Elements.Code
pre {
  padding: .5rem;
  border: .25rem solid $color-white--medium;
  font-size: .9rem;
  max-width: 100%;
  max-width: calc(100vw - 3rem);
  overflow: auto;
}

code.highlighter-rouge {
  background-color: $color-highlight;
  padding: .1rem .25rem;
}