.not-found {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100vh;
  // img {
  //   margin: 2rem auto;
  //   max-width: 8rem;
  //   // opacity: .5;
  // }
  // hr {
  //   width: 80%;
  //   color: gray;
  // }
  h1 {
    font-weight: bold;
    text-align: center;
    font-size: 10rem;
    margin: 0 1rem;
    height: 10rem;
    line-height: 10rem;
    color: gray;
  }
  p {
    font-size: 2rem;
  }
  .c-button {
    max-width: 20rem;
    margin: 2rem auto;
  }
}