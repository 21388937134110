.s-services {
  .o-content {
    order: 1;
    @media (max-width: $md) {
      order: 0;
    }
    margin: 1.5rem auto auto 1.5rem;
    & > .c-icon {
      transform: none;
      right: 1rem;
      top: 2rem;
      position: absolute;
      width: 3.75rem;
      height: 3.75rem;
      opacity: .65;
    }
    h2 {
      margin-top: 1rem;
      max-width: calc(100% - 4rem);
    }
    p:first-of-type {
      margin-top: .75rem;
    }
    ul {
      padding-left: 1.2rem;
    }
    .service-list {
      display: none;
      & + ul {
        list-style: none;
        border-right: .5rem solid #eee;
        margin-right: 1rem;
        padding-right: 1rem;;
        li { max-width: 40rem; position: relative; margin: .5rem; }
        li:before {
          position: absolute;
          content: "▪";
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          margin-left: -2rem;
          font-size: 2rem;
          height: 100%;
          // vertical-align: sub;
        }
        li:nth-of-type(1):before { color: $color-accent; }
        li:nth-of-type(2):before { color: $color-green; }
        li:nth-of-type(3):before { color: $color-blue; }
      }
    }
  }

  .o-sidebar {
    ul {
      li:nth-of-type(1) { color: $color-accent; }
      li:nth-of-type(2) { color: $color-green; }
      li:nth-of-type(3) { color: $color-blue; }
      em { font-style: normal; color: initial; }
    }
    img {
      max-width: 30%;
    }
  }
}

.s-web-services  {
  .o-content > .c-icon {
    fill: $color-accent;
  }
}

.s-app-services  {
  .o-content > .c-icon {
    fill: $color-green;
  }
}

.s-social-services  {
  .o-content > .c-icon {
    fill: $color-blue;
  }
}

.s-hosting-services {
  .o-content {
    margin: auto;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
    section {
      padding: 1rem;
    }
  }
  table.consolidated {
    margin: 1rem auto;
    border-spacing: 1px;
    img {
      max-width: 100%;
      height: 1rem;
      display: block;
      margin: auto;
      opacity: .85;
    }
  }
  table thead th,
  table tfoot td {
    padding: .5rem 1rem;
    color: $color-white--light;
    background-color: rgba($color-black--medium, .65);
    font-weight: normal;
  }
  table tfoot th {
    padding: .5rem 1rem .25rem;
    text-transform: uppercase;
    color: $color-gray--dark;
    text-align: right;
  }
  table tfoot td {
    background-color: rgba($color-black--dark, .95);
    color: $color-orange;
    text-align: center;
    font-size: 125%;
    span { font-size: 75%; margin-right: .1rem; color: desaturate($color-orange, 80%); }
  }
  table thead th:empty {
    background:transparent;
  }
  table tbody td:empty:before {
    content: '✕';
    color: $color-accent;
  }
  table tbody th {
    text-align: right;
    padding: .5rem 1rem;
    color: $color-black--light;
    // text-shadow: 1px 1px 1px $color-gray--light;
    background-color: rgba($color-white--dark, .5);
  }
  table tbody td {
    padding: .5rem 1rem;
    background-color: rgba($color-white--light, .5);
    color: black;
    text-align: center;
    font-family: $secondary-font-family;
  }
  table ~ p {
    font-size: .8rem;
    text-align: center;
    max-width: 785px;
    // width: 50%;
    // background-color: rgba($color-white--dark, .85);
  }

  .plans {
    table {
      display: none;
      width: 100%;
      margin: 0 auto 1rem;
    }
  }

  @media (max-width: $md) {
    table.consolidated {
      display: none;
    }
    .plans table {
      display: table;
    }
  }


}