// Offers
//
// Use with class `.c-offers`.
//
// See <a href="kitchensink.html#offers" target="_blank">Kitchensink</a> for examples.
//
// Styleguide: Components.Offers

.c-offers {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: $lg;
  margin: auto;
  & > div {
    max-width: $lg;
    margin: 0 auto;
    padding: map-get($line-heights, xlarge) 1rem 0;
  }
  & > p {
    font-size: .8rem;
    text-align: center;
    width: 50%;
    margin: auto;
    background-color: rgba(0,0,0,.25);
    color: $color-white--dark;
  }
}

.c-offers__item {
  width: calc(100% / 5);
  color: $color-white--medium;
  padding: 1rem;
  flex-grow: 1;
  text-align: center;
  @media (min-width: $md) {
    border-right: 1px solid $color-gray--medium;
    &:last-of-type { border-right: 0; }
  }
  img {
    height: 2rem;
    width: 2rem;
  }
  h3 {
    display: block;
    width: 100%;
    height: 1.75rem;
    overflow: hidden;
  }
  em {
    height: 4.5rem;
    overflow: hidden;
    font-size: 85%;
    line-height: 1.25;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-gray--medium;
    @media (min-width: $lg) {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .features {
    margin: 1rem 0 1rem 1rem;
    list-style-image: url(../images/favicon-inverted.png);
    text-align: left;
    font-size: 90%;
    border-bottom: 1px solid $color-gray--medium;
    padding-bottom: 1rem;
    @media (min-width: $md) {
      min-height: 13.75rem;
    }
    @media (min-width: $lg) {
      min-height: 11.25rem;
    }
    li {
      line-height: 1.25;
      margin: .5rem 0;
      @media (min-width: $lg) {
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
      }
      &.disabled {
        color: $color-gray--medium;
        filter: grayscale(1);
        opacity: .5;
      }
    }
  }
  .price {
    text-align: right;
    border-bottom: 1px solid $color-gray--medium;
    span {
      font-size: 2rem;
      // font-family: $default-font-family;
    }
  }
  .extras {
    margin: 1rem 0 1rem 1rem;
    padding-bottom: 1rem;
    list-style-image: url(../images/list-plus.png);
    text-align: right;
    // border-bottom: 1px solid $color-gray--medium;
    li {
      border-bottom: 1px solid $color-gray--dark;
      padding: .25rem 0;
      line-height: 1;
    }

    span {
      font-size: 1.2rem;
      display: inline-block;
      margin-top: .2rem;
    }
    i {
      // float: left;
      font-style: normal;
      font-size: 90%;
      margin-top: 1px;
      font-family: $secondary-font-family;
    }
  }
}
