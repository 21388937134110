.s-home footer {
  text-align: right;
  padding: map-get($line-heights, small)/2 1rem map-get($line-heights, large);
  max-width: $xl;
  margin: 0 auto;
}

.s-home__intro {
  .banner {
    aspect-ratio: 1.71;
    background: $background-banner-image repeat fixed;
    @media (max-width: $md) {
      // display: none;
      aspect-ratio: 1.75;
    }
    object {
      margin: auto;
      display: block;
      height: 100%;
      // width: 90%;
      // max-width: $lg;
      border: 0;
      &[data=''] {
        display: none
      }
      // max-height: calc(100vh - 60px);
    }
    picture {
      display: flex;
    }
    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1.75;
    }
  }

  .text {
    max-width: $md;
    margin: auto;
    padding: 1.5rem 1rem;
    color: $color-black--light;
    font-family: $primary-font-family;
  }

  p:first-of-type::first-letter {
    font-family: $secondary-font-family;
    margin-right: 3px;
  }
  @at-root html[lang="en"] & p:first-of-type::first-letter {
    margin-right: -1px;
  }
}

.s-home__features {
  max-width: $lg;
  margin: map-get($line-heights, xlarge) auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  h3:first-letter {
    margin-right: 1px;
    line-height: 0;
    position: absolute;
  }
  article {
    margin-bottom: 0;
    margin-top: 0;
    padding: 1rem;
    flex-grow: 1;
    border-right: 1px solid $color-gray--light;
    @media (min-width: $lg) {
      p { min-height: 380px; }
    }
    &:last-of-type {
      border-right: 0;
      h3:first-letter {
        margin-right: 0;
      }
    }
    &:first-of-type {
      h3:first-letter {
        margin-right: 3px;
      }
    }
    @media (max-width: $md) {
      border-right: 0;
    }
  }

  svg {
    transform: none;
    left: calc(50% - 3.75rem);
    top: auto;
    bottom: 0;
    opacity: .1;
    position: absolute;
    margin: 0;
    width: 7.5rem;
    @media screen and (max-width: $md) {
      position: relative;
    }
  }
}

.s-home__audits {
  padding: 1rem 0;
  background-color: rgba($color-black--dark, .25);
  &.out-viewport {
    .circle__path {
      stroke-dasharray: 0,9999;
    }
    .circle.pwa circle + path {
      fill: gray;
    }
  }
  &.in-viewport {
    .circle__path {
      stroke-dasharray: 314;
    }
    .circle.pwa circle + path {
      fill: #0cce6b;
    }
  }
  .u-grid {
    max-width: $xl;
    margin: auto;
    text-align: center;
  }
  .audit {
    margin: 1rem auto;
    color: rgb(245, 245, 245);
    @media screen and (max-width: $sm) {
      width: 100% !important;
    }
  }
  .circle {
    max-width:7rem;
    max-height:7rem;
    width:100%;
    &:not(.pwa) {
      will-change: transform;
      transform: scaleX(-1) rotate(-55deg);
    }

    &__percent {
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      color: #0cce6b;
      font-family: monospace;
      font-size: 2rem;
    }

    &__container {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position:relative;
    }

    &__path, path {
      transition: 1s ease-in-out stroke-dasharray, 1.5s fill;
    }

    &__label {
      margin-top: .75rem;
      font-size: 1.15rem;
      order: 2;
    }

  }
}

.s-home__offers {
  .c-offers__item:last-of-type {
    img:first-of-type {
      margin-right: 2px;
    }
  }
}

.s-home__templates {
  .c-cards > a {
    @media screen and (max-width: $md) {
      width: calc(100% - 1rem);
    }
    @media screen and (max-width: $lg) {
      margin-bottom: 2rem;
    }
  }
  .c-cards + footer {
    @media screen and (max-width: $lg) {
      margin-top: -2rem;
    }
  }
}

.s-home__virtues {
  @media (max-width: $md) {
    .c-cards__item {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
