// *** FONTS ***
// *************

@font-face {
  font-family: 'opensans';
  src: url('../fonts/Opensans/opensans-regular-webfont.woff2') format('woff2'),
       url('../fonts/Opensans/opensans-regular-webfont.woff') format('woff'),
       url('../fonts/Opensans/opensans-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family: 'roboto_slab';
    src: url('../fonts/Robotoslab/robotoslab-regular-webfont.woff2') format('woff2'),
         url('../fonts/Robotoslab/robotoslab-regular-webfont.woff') format('woff'),
         url('../fonts/Robotoslab/robotoslab-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.u-default-font { font-family: $default-font-family; }
.u-primary-font { font-family: $primary-font-family; }
.u-secondary-font { font-family: $secondary-font-family; }


// *** BACKGROUNDS ***
// *******************

.u-background {
  @media(min-width: 2560px) {
    background-size: cover;
  }
}

.u-background-black {
  @extend .u-background;
  background-color: $color-black--dark;
}

.u-background-dark {
  @extend .u-background;
  background-color: $color-black--medium;
  &--textured { background: $color-black--medium $background-section-image; }
}

.u-background-light {
  @extend .u-background;
  background-color: $color-white--light;
  &--textured { background: $color-white--light $background-section-image; }
}

.u-background-primary {
  @extend .u-background;
  background: $color-primary $background-banner-image;
  &--grayscale {
    // @extend .u-background-primary;
    position: relative;
    &:before {
      filter: grayscale(1) brightness(1.25);
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      display: block;
      background-image: $background-banner-image;
      background-size: cover;
    }
  }
  &--inverted {
    // @extend .u-background-primary;
    position: relative;
    background-color: rgba($color-black--dark, .1);
    &:before {
      filter: grayscale(1) brightness(.25);
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      display: block;
      background-image: $background-banner-image;
      background-size: cover;
    }
  }
  &--fixed {
    // @extend .u-background-primary;
    position: relative;
    &:before {
      background-attachment: fixed;
      // transform: rotate(180deg);
      filter: grayscale(.9) brightness(1.25) hue-rotate(180deg);
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      display: block;
      background-image: $background-banner-image;
      background-size: cover;
    }
  }
}


// *** TEXT PROPERTIES ***
// *******************
.u-text-small { @include type-setting(small) }
.u-text-normal { @include type-setting(normal) }
.u-text-large { @include type-setting(large) }
.u-text-xlarge { @include type-setting(xlarge) }

.u-text-left { text-align: left; }
.u-text-center { text-align: center; }
.u-text-right { text-align: right; }

.u-big-first-letter::first-letter { font-size: 250%; font-weight: bold; line-height: 0; }

// *** VISIBILITY ***
// *******************
.u-hidden { display: none; }
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// *** MARGINS ***
// *******************
.u-subheader { margin-top: 0; }

// *** VERTICAL ALIGN ***
// *******************
.u-align-top { vertical-align: top; }
.u-align-middle { vertical-align: middle; }
.u-align-baseline { vertical-align: baseline; }

// *** GRID ***
// *******************
.u-grid { width: 100%; font-size: 0; }
.u-grid > * { font-size: 1rem; display: inline-block; margin: .5rem; vertical-align: top; }
.u-grid--cols-2 > * { width: calc(50% - 1rem); }
.u-grid--cols-3 > * { width: calc(33.33% - 1rem); }
.u-grid--cols-4 > * { width: calc(25% - 1rem); }
.u-grid--cols-5 > * { width: calc(20% - 1rem); }
.u-grid--cols-6 > * { width: calc(16.66% - 1rem); }
@media (max-width: $lg) {
  // .u-grid > *  { width: 100%; }
  // .u-grid--cols-4 > * { width: 100%; }
  // .u-grid--cols-2 > * { width: 100%; }
  .u-grid--cols-6 > * { width: calc(33.33% - 1rem); }
}

@media (max-width: $md) {
  .u-grid--cols-2 > * { width: 100%; }
  .u-grid--cols-3 > * { width: 100%; }
  .u-grid--cols-4 > * { width: calc(50% - 1rem); }
  .u-grid--cols-5 > * { width: calc(33.33% - 1rem); }
  .u-grid--cols-5 > *:nth-child(4),
  .u-grid--cols-5 > *:nth-child(5) { width: calc(50% - 1rem); }
  .u-grid--cols-6 > * { width: calc(50% - 1rem); }
}

@media (max-width: $sm) {
  .u-grid--cols-2 > *
  .u-grid--cols-3 > *,
  .u-grid--cols-4 > *,
  .u-grid--cols-5 > *,
  .u-grid--cols-6 > * {
    width: 100%;
  }
}