.o-header,
.o-footer {
  padding: 1rem;
}

.o-container {
  display: flex;

  .o-sidebar {
    flex-basis: 25%;
    padding: 1rem 5vmin 3rem;
    &--flipped {
      @extend .o-sidebar;
      @media (min-width: $md) {
        order: 1;
      }
    }
  }

  .o-content {
    flex-basis: 75%;
    max-width: $md;
    margin: 0 auto auto;
    padding: 1rem 1rem 3rem;
    order: 0;
    position: relative;
    img {
      max-width: 100%
    }
    li > a {
      // text-overflow: ellipsis;
      max-width: calc(100vw - 3rem);
      overflow: hidden;
      white-space: nowrap;
      display: inline-flex;
      vertical-align: middle;
      margin-bottom: .5rem;
    }
  }
}

@media (max-width: $md) {
  .o-container {
    flex-wrap: wrap;
    .o-sidebar, .o-content {
      flex-basis: 100%;
    }
  }
}
