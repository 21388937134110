.s-help {
  .o-container {
    flex-direction: column;
    .c-icon {
      fill: gray;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .search-wrap {
    background-color: $color-blue;
    padding: 1.5rem;
    max-width: 100%;
    margin: 0;
    .search-text {
      margin: 0 auto 1rem;
      color: white;
      max-width: $sm;
      text-align: center;
    }
    .input-wrap {
      max-width: $sm;
      margin: auto;
      display: block;
      position: relative;
      .c-icon {
        position: absolute;
        z-index: 1;
        top: .5rem;
        left: .5rem;
      }
      input {
        padding-left: 2.5rem;
        opacity: .95;
        border-radius: 2px;
        box-shadow: 0 0 0 1px white;
      }
    }
  }
  .o-content {
    width: 100%;
    max-width: $tb;
    .u-hidden {
      display: none;
    }
    .u-grid {
      text-align: center;
      & > div {
        text-align: left;
      }
    }
    .no-results {
      text-align: center;
      color: $color-accent;
      font-size: 1.1rem;
      font-weight: bold;
      margin-top: 2rem;
    }
    h3 {
      margin-bottom: .25rem;
      & > * {
        vertical-align: middle;
      }
    }
    ul {
      list-style-type: none;
    }
    li {
      margin: 0 0 .5rem .5rem;
      & > a {
        display: inline;
        white-space: normal;
      }
    }
  }
}
