.s-blog .o-content {
  article {
    padding: 1rem;
    margin: 0;
  }
  p {
    max-width: $sm;
    margin-left: 0;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .featured {
    background-color: $color-white--dark;
    width: 100%;
    margin-top: .5rem;
    padding-bottom: 1.5rem;
    h3 { margin-top: 0 }
    img {
      height: 120px;
      width: auto;
      max-width: 100%;
      border-radius: 15% 0;
      padding-bottom: .5rem;
    }
    p {
      max-width: $md;
    }
  }
  .u-grid .c-button {
    margin: 0;
    padding: .5rem 1.5rem;
  }
  img {
    height: 5rem;
    width: 5rem;
    object-fit: cover;
    float: left;
    margin-right: 1rem;
  }
}

.s-blog .o-sidebar {
  .c-tabs {
    max-width: $xs;
    justify-content: center;
  }
  .c-tabs__content {
    max-width: 100%;
    margin-bottom: 1rem;
  }
  @media (max-width: $md) {
    h3 {
      text-align: center;
    }
    .c-tabs {
      max-width: calc(100vw - 20vmin);
      margin: auto;
    }
  }
  a {
    line-height: 1.2;
    display: inline-block;
    max-height: 2.5rem;
    margin-top: .5rem;
    border-color: transparent;
  }
  small {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -.25rem;
    padding-bottom: .25rem;
    border-bottom: 1px solid $color-gray--light;
    color: $color-blue;
    font-size: 75%;
  }
}

.related > div {
  padding: .75rem 0;
  border-bottom: 1px solid $color-gray--light;
  a {
    line-height: 1.2;
    display: inline-block;
    max-height: 2.5rem;
    margin-top: .25rem;
    border-color: transparent;
  }
}