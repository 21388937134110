// button-colorize($color)
//
// Creates button styling with the specified color
//
// <br>Usage: <br>
// - @include button-colorize(green);
//
// Parameters:<br>
// - $color - Button color.
//
// Styleguide: Mixins.button-colorize
@mixin button-colorize($color) {
  background-color: $color;
  // border:1px solid darken($color, 20%);
  // text-shadow:0 2px 0 darken($color, 10%);
  // box-shadow:0 3px 0 0 darken($color, 30%), inset 0 1px 3px rgba(255,255,255,0.4);
}

@function type-scale($level) {
  @return map-get($type-scale, $level);
}

@function line-height($level) {
  @return map-get($line-heights, $level);
}

// type-setting($level: normal)
//
// Sets the font-size and line-height
//
// <br>Usage:<br>
// - @include type-setting(large);
//
// Parameters:
// - $level - The size of the type-setting (small, normal, large, xlarge) | Default: normal
//
// Styleguide: Mixins.type-setting
@mixin type-setting($level: normal) {
  font-size: type-scale($level);
  line-height: line-height($level);
}
