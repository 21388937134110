// Breadcrumbs
//
// Navigational aid on all pages except homepage
//
// Markup:
// <nav class="c-breadcrumbs">
//   <ol vocab="http://schema.org/" typeof="BreadcrumbList">
//     <li property="itemListElement" typeof="ListItem">
//       <a property="item" typeof="WebPage" href="#">
//         <svg class="c-icon c-icon--large"><use xlink:href="#icon-home" title="Αρχική"></use></svg>
//         <meta property="position" content="1">
//       </a>
//     </li>
//     <li property="itemListElement" typeof="ListItem">
//       <a property="item" typeof="WebPage" href="#">
//         <span property="name">Υπηρεσίες</span>
//         <meta property="position" content="2">
//       </a>
//     </li>
//     <li property="itemListElement" typeof="ListItem">
//       <h1 property="name">Ιστοσελιδες</h1>
//       <meta property="position" content="3">
//     </li>
//   </ol>
// </nav>
//
// Styleguide: Components.Breadcrumbs

.c-breadcrumbs {
  text-align: center;
  background-image: $background-banner-image;
  background-position-y: 1%;
  background-size: cover;
  font-family: $secondary-font-family;
  padding: 1rem;
  li {
    display: inline-block;
    font-size: 1.2rem;
    // padding-right: .5rem;
    white-space: nowrap;
    & + li {
      // padding-right: 2rem;
      &:before {
        display: inline-block;
        padding-right: .5rem;
        padding-left: .5rem;
        color: $color-gray--dark;
        // position: absolute;
        // float: left;
        // font-size: 1.4rem;
        // margin-top: .2rem;
        // margin-left: -1.25rem;
        content: "/"; //"▸"; // ▹
        // vertical-align: text-bottom;
      }
    }
    h1 {
      margin: .25rem 0;
      max-width: 100%;
    }
    span, h1, .icon {
      vertical-align: middle;
    }
    &:first-of-type a {
      border-color: transparent;
      &:hover {
        border-color: initial;
        color: $color-black--dark;
      }
      margin-right: .5rem;
    }
    &:last-of-type h1 {
      color: $color-black--light;
      opacity: .85;
      display: inline-block;
      white-space: normal;
      @media (max-width: $md) {
        max-width: 80vw;
      }
    }
  }
}
