.pagination {
  text-align: center;
  margin-top: 2rem;

  .c-button {
    padding: .5rem 1rem;
    margin: .2rem 0;
    &.active {
      background-color: $color-green;
      color: $color-white--light;
    }
  }

}