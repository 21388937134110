// Button
//
// Buttons in the site are built from the same base class (`.c-button`)
//
// Markup:
// <a href="#" class="c-button ">Link Button</a>
// <button class="c-button ">Button Element</button>
// <input type="button" class="c-button " value="input[type='button']"/>
//
// .c-button--primary          - Use this class to indicate that the button is the primary feature.
// .c-button--inverted         - Use this class to indicate that the button has inverted colors.
// .c-button--overlay          - Use this class for an overlay effecy when hovering.
// .c-button--arrow            - Use this class to append an arrow when hovering.
// :hover            - Highlight the button when hovered.
// :focus            - Highlight the button when focused.
// :disabled         - Make the button change appearance to reflect it being disabled.
//
// Styleguide: Components.Button

.c-button {
  border: 3px solid $color-black--medium;
	color: inherit;
  background: none;
  max-width: 100%;
  text-overflow: ellipsis;
	cursor: pointer;
	display: inline-block;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
  transition: all 0.3s;
  overflow: hidden;
  padding: .5rem 3rem;
  margin: .5rem;
  height: 2.8rem;
  text-decoration: none;
  vertical-align: middle;
  font-family: inherit;
  white-space: nowrap;
  font-size: inherit;
  z-index: 1;

  &--overlay:not(.active) {
    &:before {
      width: 105%;
      height: 0;
      top: 50%;
      left: 50%;
      background: $color-black--medium;
      opacity: 0;
      content: '';
      position: absolute;
      z-index: -1;
      transition: all 0.2s;
      transform: translateX(-50%) translateY(-50%);
    }

    &:hover {
      color: $color-primary;
    }

    &:active {
      color: $color-white--light;
    }

    &:hover:before, &:active:before {
      height: 105%;
      opacity: 1;
    }
  }

  &--arrow {
    &:after {
      position: absolute;
      height: 100%;
      font-size: 150%;
      line-height: 1.5;
      color: inherit;
      transition: all 0.3s;
      right: 15%;
      opacity: 0;
      top: 0;
      content: "⇢";
    }

    &:hover:after {
      right: 1rem;
      opacity: 1;
    }

    &:active:after {
      color: $color-primary;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: $color-gray--medium;
    border-color: $color-gray--medium;
  }

  &--primary {
    // @extend .button;
    @include button-colorize($color-primary);
    &:hover, &:focus {
      border-color: $color-black--light;
      background-color: lighten($color-primary, 20%);
    }
  }

  &--inverted {
    // @extend .button;
    border-color: $color-white--dark !important;
    color: $color-white--light !important;

  }

  @media (max-width: $md) {
    padding: .5rem 2rem;
    &--arrow:hover:after {
      right: .375rem;
    }
  }

}
