// Icon
//
// Icons are <b>svg</b> defs defined in <i>/assets/svg/icons.svg</i> and used inline with the class `.c-icon`.
//
// Markup:
// <svg class="c-icon "><use xlink:href="#icon-home"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-globe"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-newspaper-o"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-shopping-cart"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-television"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-desktop"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-mobile"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-cogs"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-line-chart"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-leaf"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-group"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-envelope"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-phone"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-clock-o"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-facebook"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-twitter"></use></svg>
// <svg class="c-icon "><use xlink:href="#icon-lightbulb-o"></use></svg>
//
// c-icon--large            - Use this class for larger icons.
// c-icon--background       - Use this class for using an icon as background decoration
// c-icon--white            - Use this class on a dark background
//
// Styleguide: Components.Icon

.c-icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  width: 1rem;
  height: 1rem;

  &--large {
    // @extend .c-icon;
    width: 1.8rem;
    height: 1.8rem;
    vertical-align: middle;
  }

  &--background {
    // @extend .c-icon;
    // position: absolute;
    // left: 5%;
    // top: 15%;
    width: 7.5rem;
    height: 7.5rem;
    transform: rotate(-15deg);
    opacity: .5;
  }

  &--white {
    // @extend .c-icon;
    fill: $color-white--light;
  }

  &--feed {
    color: darkorange;
    margin-left: 1rem;
    &:hover { color: orange; }
  }

}