// Cards
//
// Use with class `.c-cards` and `.c-cards--shots`.
//
// See <a href="kitchensink.html#cards" target="_blank">Kitchensink</a> for examples.
//
// Styleguide: Components.Cards

.c-cards {
  max-width: $xl;
  margin: auto;
  padding: 1rem;
  text-align: center;
}
.c-cards > a {
  border: 5px solid white;
  box-shadow: 5px 5px 5px #222;
  position: relative;
}
.c-cards > a:hover, .c-cards > a:focus {
  strong {
    border-color: white;
  }
}

.c-cards strong {
  font-size: 18px;
  font-variant-ligatures: none;
  background-color: #222;
  color: white;
  padding: 5px 10px;
  border: 5px solid transparent;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: -25px;
  transition: border-color .3s;
}

.c-cards img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
  vertical-align: middle;
  filter: brightness(.9);
  transition: filter .2s;
 }

.c-cards > a:hover img, .c-cards > a:focus img {
  filter: brightness(1);
}

.c-cards--shots {
  .project {
    // display: inline-block;
    // box-shadow: 0 0 3px white;
    @media screen and (max-width: $md) {
      margin: 1rem .5rem 2rem;
      max-width: calc(100% - 1rem);
    }
    // width: 24%;
    // line-height: 0;
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 3/2;
    }

    a {
      display: block;
      border-bottom: 0;
      background-color: rgba(255,255,255,.25); transition: all .1s ease-in;
      white-space: nowrap;
      span {
        display: block;
        padding: 4px;
        margin: 1rem 0 0;
        line-height: 2;
        color: white;
        font-size: 1rem;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        &::before, &::after { display: inline-block; opacity: 0; transition: transform 0.3s, opacity 0.2s; }
        &::before { margin-right: 10px;	content: '[';	transform: translateX(20px); }
        &::after { margin-left: 10px; content: ']'; transform: translateX(-20px); }
      }
    }

    a:hover span::before, a:hover span::after,
    a:focus span::before, a:focus span::after {
      opacity: 1; transform: translateX(0px);
    }

    a:hover img {
      filter: none;
      opacity: 1;
    }

    a img {
      display: block;
      filter: grayscale(1);
      transition: all .3s;
      opacity: .85;
      max-height: 80px;
      max-width: 255px;
      margin: auto;
      object-fit: contain;
    }
  }
}

.c-cards--reveal {
  overflow: hidden;
  .c-cards__header {
    position: relative;
    h3 { margin: 2rem 1rem 0; line-height: 0; }
    .c-icon {
      position: absolute;
      top: -2.5rem;
      left: -1.5rem;
      width: 3rem;
      height: 3rem;
      color: $color-blue;
    }
  }
  .c-cards__item {
    background: rgba(0,0,0,.05);
    padding: 0 1rem;
  }
  @media (min-width: $md) {
    .u-grid {
      display: flex;
    }
  }
  .u-grid--cols-3 {
    .c-cards__item:nth-child(1),
    .c-cards__item:nth-child(3)  {
      background: rgba(0,0,0,.15);
    }

  }
  .u-grid--cols-2 {
    .c-cards__item {
      background: rgba(0,0,0,.1);
    }
    .c-cards__item:last-child .c-icon {
      right: -1.5rem;
      left: auto;
      transform: rotate(15deg);
    }
  }
}
