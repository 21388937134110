.s-portfolio {
  .c-tabs {
    margin: 2rem auto;
    min-height: calc(100vh - 4rem);
    max-width: $lg;
    label {
      margin: auto auto .5rem;
      &:first-of-type { margin-right: 0; }
      &:last-of-type { margin-left: 0; }
      color: $color-white--medium;
    }
  }
  .c-tabs__content {
    min-height: calc(100vh - 4rem);
    .c-cards {
      text-align: left;
      .project {
        margin-top: 1rem;
        margin-bottom: 1rem;
        position: relative;
        .offline {
          position: absolute;
          font-size: 1rem;
          font-weight: bold;
          font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
          right: 0;
          margin-top: -1rem;
          color: $color-pink;
          border: 2px solid rgba(red, .5);
          border-radius: .5rem;
          transform: rotate(-20deg);
          padding: .25rem
        }
      }
    }
  }
  @media (min-width: $md) {
    .project img {
      transition: $transition-fast;
    }
    .project:hover img, .project:focus img {
      transform: scale(1.05);
    }
  }
}