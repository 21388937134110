// Forms
//
// CSS-only form implementation. Use the `.c-form` class.
//
// Markup:
// <form class="c-form">
//   <label for="name" class="c-form__required">Full name</label>
//   <input id="name" name="name" type="text" required>
//   <label for="email" class="c-form__required">E-mail</label>
//   <input id="email" name="email" type="email" required>
//   <label for="phone">Telephone</label>
//   <input id="phone" name="phone" type="telephone">
//   <button class="c-button c-button--primary" type="submit" />Submit</button>
// </form>
//
// Styleguide: Components.Forms

.c-form {
  margin: 1.5rem 0;
  max-width: $sm;
  fieldset {
    border: 0;
  }
  label {
    display: block;
    padding-bottom: .1rem;
  }
  input:not([type=submit]), textarea, select {
    padding: .5rem;
    background-color: $color-white--light;
    color: $color-black--dark;
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid $color-gray--light
  }
  input {
    height: 2.5rem;
  }
  input[type=submit] {
    margin: 1rem 0;
  }
}

.c-form__required:after {
  content: "*";
  color: red;
  display: inline-block;
  padding-left: .5rem;
}