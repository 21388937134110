// Side Navigation
//
// Markup:
// <nav class="p-nav-services">
//   <a href="#" class="active">
//     <svg class="c-icon c-icon--large p-nav-services__item--web-services" aria-hidden="true">
//       <use xlink:href="#icon-globe"></use>
//     </svg>
//     <span>Ιστοσελιδες</span>
//   </a>
//   <a href="#">
//     <svg class="c-icon c-icon--large p-nav-services__item--app-services" aria-hidden="true">
//       <use xlink:href="#icon-television"></use>
//     </svg>
//     <span>Web Εφαρμογές</span>
//   </a>
//   <a href="#">
//     <svg class="c-icon c-icon--large p-nav-services__item--social-services" aria-hidden="true">
//       <use xlink:href="#icon-line-chart"></use>
//     </svg>
//     <span>SEO & Marketing</span>
//   </a>
// </nav>
//
// Styleguide Patterns.Sidenav

.p-nav-services {
  margin: 2rem 0 1rem auto;
  font-family: $secondary-font-family;
  max-width: $xs;
  @media (max-width: $md) {
    margin-right: auto;
    max-width: 100%;
  }
  a {
    display: flex;
    padding: .5rem;
    border-bottom: 1px dashed $color-gray--medium;
    // &:hover, &:focus {
    //   border-bottom-color: $color-black--dark;
    // }
    &.is-active {
      font-weight: bold;
    }
    span {
      align-self: center;
    }
  }
}

.p-nav-services__item {
  &--cat1, &--cat2, &--cat3,
  &--web-services, &--app-services, &--social-services {
    // @extend .p-nav-services__item;
    margin: auto .5rem auto 0;
    align-self: center;
  }
  &--cat1, &--web-services { fill: $color-accent; }
  &--cat2, &--app-services { fill: $color-green; }
  &--cat3, &--social-services { fill: $color-blue; }
}
