.s-contact {
  .o-content {
    display: block;
  }
   [data-selector] {
    margin-top: .5rem;
    font-weight: bold;
  }
  [data-selector=success_msg] {
    color: $color-green;
  }
  [data-selector=error_msg] {
    color: $color-accent;
  }
}