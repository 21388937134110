// Tabs
//
// CSS-only tabs implementation. Use the `.c-tabs` class.
//
// Markup:
// <div class="c-tabs ">
//   <input id="tab1" type="radio" name="tabs" checked>
//   <label for="tab1">Tab 1</label>
//   <input id="tab2" type="radio" name="tabs">
//   <label for="tab2">Tab 2</label>
//   <div class="c-tabs__content">
//     <div id="content1">
//       <p>Content 1</p>
//     </div>
//     <div id="content2">
//       <p>Content 2</p>
//     </div>
//   </div>
// </div>
//
// .c-tabs--bottom            - Use this class for tabs control at the bottom.
//
// Styleguide: Components.Tabs

.c-tabs {
  display: flex;
  flex-wrap: wrap;
  &::after {
    content: '';
    width: 100%;
  }

  & > input {
    display: none;
  }

  & > label {
    display: inline-block;
    padding: 1rem;
    font-weight: bold;
  }

  & > label:hover {
    cursor: pointer;
  }

  & > input:checked + label {
    background: $color-green;
    color: $color-white--light;
  }

  #tab1:checked ~ .c-tabs__content #content1,
  #tab2:checked ~ .c-tabs__content #content2,
  #tab3:checked ~ .c-tabs__content #content3,
  #tab4:checked ~ .c-tabs__content #content4,
  #tab5:checked ~ .c-tabs__content #content5,
  #tab6:checked ~ .c-tabs__content #content6 {
    display: block;
  }

  &--bottom {
    .c-tabs__content { order: 0; }
    & > label { order: 1; }
  }

}

.c-tabs__content {
  order: 1;
  & > * {
    display: none;
  }
}
