// Page Footer
//
// Markup:
// <footer class="p-footer">
//   ...
// </footer>
//
// Styleguide Patterns.Footer

.p-footer__container {
  max-width: $lg;
  margin: auto;
  padding: 1rem 1rem 0;
  display: flex;
  @media (max-width: $md) {
    display: block;
    text-align: center;
    padding: 2rem 1rem;
  }
}

.p-footer__request {
  .header {
    flex: 1;
    color: $color-white--medium;
  }
  h2 {
    @include type-setting(large);
    font-weight: normal;
    font-family: $secondary-font-family;
    color: $color-white--dark;
    display: inline-block;
    margin: -1px 0 1px;
    padding: 0 .5rem;
    background-color: transparent;
    // background-color: rgba($color-black--dark, .5);
  }
  p {
    display: inline-block;
    padding: 0 .5rem;
    // background-color: rgba($color-black--dark, .9);
  }
  .c-button {
    margin: .625rem;
  }
  .c-icon {
    width: 3.5rem;
    height: 3.5rem;
    transform: rotate(-15deg);
    opacity: .85;
    stroke: $color-primary;
    stroke-width: 1rem;
    margin: 0 1rem;
  }

}

.p-footer__links {
  // text-align: center;
  .u-grid {
    padding: 0 1rem 2rem;
    max-width: $lg;
    margin: auto;
  }
  .c-icon {
    margin: 0 .25rem;
    vertical-align: text-top;
  }
  a, span.hidden-field {
    color: $color-white--medium;
    margin: .5rem auto;
    display: block;
    font-size: .85rem;
    max-width: 90%;
    line-height: 1.5;
  }
  a:hover {
    color: $color-white--light;
    border-color: $color-white--light;
  }
  span.hidden-field {
    color: gray;
    margin-bottom: 0;
    & + a {
      margin: 0 auto;
    }
  }
  img {
    width: 8rem;
    margin: 3rem auto 1rem;
    display: block;
    // filter: grayscale(1) brightness(.1);
  }
  h3 {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
  h3, h4 {
    color: $color-white--dark;
    a {
      display: inline;
      font-size: inherit;
    }
  }
  .rss {
    position: relative;
    h3 {
      padding-right: 3rem;
      div { display: inline-block; }
    }
    & > a.has-icon {
      position: absolute;
      right: 1rem;
      top: 1.5rem;
      border-bottom: 0;
    }
    .c-icon {
      color: darkorange;
      vertical-align: baseline;
      &:hover { color: orange; }
    }
  }
  .lang {
    display: inline-block;
    width: 40%;
    margin: auto;
    text-align: center;
    // border-bottom: 0;
    img {
      width: 18px;
      height: 12px;
      display: inline-block;
      margin: 0;
    }
  }
}

.p-footer__copyright {
  padding: 1rem;
  color: $color-gray--medium;
  position: relative;
  font-size: .9rem;
  span {
    max-width: $md;
    display: block;
    margin: auto;
  }
  .c-icon {
    width: 3rem;
    height: 3rem;
    z-index: -1;
  }
  a {
    position: absolute;
    right: 1.5rem;
    top: -1.5rem;
    border-bottom: 0;
    color: $color-gray--dark;
    &:hover {
      color: $color-gray--light;
    }
  }
}